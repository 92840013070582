import styled from "@emotion/styled";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import tw from "tailwind.macro";

function ContactPage() {
  const LinkCard = styled.div`
    ${tw`min-w-0 p-4 mt-4 mb-8 bg-white rounded-lg shadow-xs`}
    border: 1px solid #ffdaa7;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;

    div {
      display: flex;
      justify-content: space-between;
      h4 {
        margin: 0.5rem 0 0.4rem 0;
      }
      svg {
        color: #ffdaa7;
        width: 1.5rem;
      }
    }

    &:hover {
      background-color: #fff7ed;
      svg {
        color: #ffb54f;
      }
    }
  `;

  return (
    <Layout>
      <SEO
        keywords={[`お問い合わせ`]}
        title="お問い合わせ"
        description="お気軽にお問い合わせください。"
      />
      <section className="md:w-2/3 mx-auto">
        <h2 className="inline-block py-3 m-4 text-2xl font-bold">
          お問い合わせ
        </h2>
        <div>
          <a
            href="https://forms.gle/ukuYaGDEhGWGZWVs6"
            target="_blank"
            rel="noreferrer"
          >
            <LinkCard>
              <div>
                <h4>お仕事のご依頼・ご相談</h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <p className="text-gray-600 dark:text-gray-400">
                Webサイト/Webアプリケーション/スマートフォン向けアプリケーション(iPhone/Android)などの開発や運用を承っております。
                <br />
                お客様の業務の課題に対して提案ご提案からシステム開発・運用までも行っておりますので、
                まずはお気軽にお問い合わせください。
              </p>
            </LinkCard>
          </a>
          <a
            href="https://forms.gle/TZpGZhT34bgnb2PW6"
            target="_blank"
            rel="noreferrer"
          >
            <LinkCard>
              <div>
                <h4>その他のお問い合わせ</h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <p className="text-gray-600 dark:text-gray-400">
                その他のお問い合わせはこちらからお願いいたします。
              </p>
            </LinkCard>
          </a>
        </div>
      </section>
    </Layout>
  );
}
export default ContactPage;
